import React from 'react'

import Layout from '../components/layout'
import OhNoImg from '../images/ohno.svg'
import * as styles from '../components/404.module.less'
import { Button } from '@adobe/react-spectrum'
import { SEO } from '../components/seo'
const NotFoundPage = () => (
    <Layout>
        <SEO
            siteMetadata={{
                title: '404: Not found',
                description: '',
                twitterCreator: '',
            }}
        />
        <div className={styles.fourofour}>
            <img src={OhNoImg} />
            <h1>Oh no...</h1>
            <h2>The page you’re looking for doesn’t seem to be here.</h2>
            <p>
                Check the spelling of the page in the address bar or go back to
                Adobe Labs homepage and start browsing from there.
                <br />
                <br />
                <Button elementType="a" href="/" variant="cta">
                    Back to Labs home page
                </Button>
            </p>
        </div>
    </Layout>
)

export default NotFoundPage
